import React from "react"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import Masonry from "react-masonry-css"

import {
  Seo,
  Layout,
  HeaderCaseStudy,
  SectionCaseStudyImageOnly,
  SectionCaseStudyQuote,
  SectionCaseStudyTextOnly,
  SectionCaseStudyWhatWeDid,
  FormEnterpriseContact,
  SectionTextWithImage,
} from "../components"
import { PageSectionTextWithImage } from "../types"
import { handleSectionBackground } from "../utils"

interface CaseStudyPage {
  location: {
    href: string
  }
  data: {
    contentfulPageCaseStudy: {
      contentful_id: string
      name: string
      summary: string
      featuredImage: {
        gatsbyImageData: IGatsbyImageData
        title: string
      }
      caseStudySections: any
      pageSections: Array<PageSectionTextWithImage>
      seo: {
        title: string
        titleTemplate: {
          extension: string
        }
        description: {
          description: string
        }
        image: {
          fixed: {
            src: string
          }
        }
      }
      listPageSummary: {
        raw: string
      }
      companyLogo: {
        gatsbyImageData: IGatsbyImageData
        title: string
      }
    }
  }
}

const CaseStudy = ({ data, location }: CaseStudyPage) => {
  const page = data.contentfulPageCaseStudy
  return (
    <Layout>
      <Seo
        title={page?.seo?.title}
        description={page?.seo?.description?.description}
        titleTemplate={page?.seo?.titleTemplate?.extension}
        article={false}
        url={location.href}
        image={page?.seo?.image?.fixed?.src}
      />
      <HeaderCaseStudy
        heading={page?.name}
        image={page?.featuredImage?.gatsbyImageData}
        imageAlt={page?.featuredImage?.title}
        logo={page?.companyLogo?.gatsbyImageData}
        logoAlt={page?.companyLogo?.title}
        summary={page?.summary}
      />
      <section className="wrapper-width wrapper-bottom">
        <Masonry
          breakpointCols={{ default: 2, 700: 1 }}
          className="case-study"
          columnClassName="case-study-column"
        >
          {page?.caseStudySections?.map((section: any, index: number) => {
            if (
              section.__typename === "ContentfulPageSectionsCaseStudyImageOnly"
            ) {
              return (
                <SectionCaseStudyImageOnly
                  key={section.contentful_id}
                  image={section.image.gatsbyImageData}
                  imageAlt={section.image.title}
                />
              )
            } else if (
              section.__typename === "ContentfulPageSectionsCaseStudyQuote"
            ) {
              return (
                <SectionCaseStudyQuote
                  key={section.contentful_id}
                  quote={section.quote}
                  quoteSource={section.quoteSource}
                />
              )
            } else if (
              section.__typename === "ContentfulPageSectionsCaseStudyTextOnly"
            ) {
              return (
                <SectionCaseStudyTextOnly
                  key={section.contentful_id}
                  content={section.content}
                />
              )
            } else if (
              section.__typename === "ContentfulPageSectionsCaseStudyWhatWeDid"
            ) {
              return (
                <SectionCaseStudyWhatWeDid
                  key={section.contentful_id}
                  heading={section.heading}
                  tickBullets={section.tickBullets}
                />
              )
            }
          })}
        </Masonry>
      </section>
      {page?.pageSections &&
        page?.pageSections.length > 0 &&
        page?.pageSections.map((section: any, index: number) => (
          <SectionTextWithImage
            key={section.contentful_id}
            contentful_id={section.contentful_id}
            backgroundColour={handleSectionBackground(
              section.backgroundColour,
              index % 2 === 0 ? "grey-lightest" : "white"
            )}
            heading={section.heading}
            content={section.content}
            image={section?.media?.media?.gatsbyImageData}
            imageAlt={section?.media?.media?.title}
            showImageFirst={section.showImageFirst}
            imageBlobBackground={section.imageBlobBackground}
            showSignUpButton={section.showSignUpButton}
            scheduleDemoButton={section.scheduleDemoButton}
            file={section?.media?.media?.file?.url}
            youTubeVideo={section?.media?.youTubeEmbedCode?.youTubeEmbedCode}
          />
        ))}
      <FormEnterpriseContact />
    </Layout>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query getCaseStudy($id: String!) {
    contentfulPageCaseStudy(contentful_id: { eq: $id }) {
      contentful_id
      caseStudySections {
        ... on ContentfulPageSectionsCaseStudyImageOnly {
          __typename
          contentful_id
          image {
            gatsbyImageData
            title
          }
        }
        ... on ContentfulPageSectionsCaseStudyQuote {
          __typename
          contentful_id
          quote
          quoteSource
        }
        ... on ContentfulPageSectionsCaseStudyTextOnly {
          __typename
          contentful_id
          content {
            raw
          }
        }
        ... on ContentfulPageSectionsCaseStudyWhatWeDid {
          __typename
          contentful_id
          heading
          tickBullets
        }
      }
      name
      summary
      featuredImage {
        gatsbyImageData
        title
      }
      pageSections {
        heading
        content {
          raw
        }
        media {
          ... on ContentfulPageSectionTextWithImageImageVideoUploa {
            media {
              title
              gatsbyImageData
              file {
                url
              }
            }
          }
          ... on ContentfulPageSectionTextWithImageYouTubeEmbed {
            youTubeEmbedCode {
              youTubeEmbedCode
            }
          }
        }
        contentful_id
        showImageFirst
      }
      seo {
        title
        titleTemplate {
          extension
        }
        description {
          description
        }
        image {
          fixed {
            src
          }
        }
      }
      listPageSummary {
        raw
      }
      companyLogo {
        gatsbyImageData
        title
      }
    }
  }
`
